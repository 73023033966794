import React from 'react';

import { ReactComponent as ReportListingIcon } from 'assets/images/icons/report-flag.svg';
import { ModalType } from 'constants/modalTypes';
import styled from 'styled-components';
import theme from 'config/theme';
import modalContainer from 'containers/modal';

const { colors } = theme;
interface ReportListingProps {
	showLoginPrompt: boolean;
	onLoginClick(): void;
}

const ReportListing = ({ showLoginPrompt, onLoginClick }: ReportListingProps) => {
	return (
		<>
			<ReportListingContainer
				onClick={() => {
					showLoginPrompt ? onLoginClick() : modalContainer.set(ModalType.ReportListing);
				}}
			>
				<ReportListingIcon />
				Report this listing
			</ReportListingContainer>
		</>
	);
};

export default ReportListing;

const ReportListingContainer = styled.div`
	background: ${colors.transparent};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: ${colors.linkBlue};
	margin-top: 16px;
	gap: 10px;
	font-weight: 700;
	font-size: 16px;
	line-height: 150%;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;
