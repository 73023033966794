import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import ReactModal from 'react-modal';
import { Subscribe } from 'unstated';
import styled from 'styled-components';

import { ReactComponent as ShareIcon } from 'assets/images/digs-listing/share.svg';
import { ReactComponent as EmailIcon } from 'assets/images/digs-listing/email.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/digs-listing/facebook.svg';
import { ReactComponent as LinkIcon } from 'assets/images/digs-listing/link.svg';
import { ReactComponent as WhatsappIcon } from 'assets/images/digs-listing/whatsapp.svg';
import api from 'api';
import modalContainer from 'containers/modal';
import authContainer from 'containers/auth';
import { ModalType } from 'constants/modalTypes';
import { EventType } from 'constants/events';
import { formatSlug } from 'utils';
import theme from 'config/theme';
import routes from 'config/routes';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import Main from 'components/common/main';
import Loading from 'components/common/page-loading';
import { Button } from 'lib/Button';
import { Divider } from 'lib/Divider';
import { Title } from 'lib/Title';
import { Video } from 'lib/Video';
import { Cross } from 'lib/Cross';
import { notifySuccess } from 'lib/Notifications';
import { analyticsService, PageName } from 'services/analyticsService';
import listingService from 'services/listingService';
import { ListingFeatureCategory } from 'models/listing';
import { BookingStatus } from 'models/booking';
import { BREAKPOINT_TABLET_LARGE_PX } from 'constants/breakPoints';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import shareService from 'services/shareService';
import { BookingPanel } from './BookingPanelV2';
import { SubTitle } from './SubTitle';
import RentalTermDetails from './rental-terms/RentalTermDetails';
import LayoutContainer from '../common/StandardLayoutWrapper';
import LandlordSection from './landlord-section';
import SimilarListingsAccordion from './similar-listings-accordion';
import ListingDescription from './listing-description';
import ListingFeatures from './listing-features';
import MapSection from './maps-section/index';
import ImageOverview from './image-overview';
import { getDigsStateData } from './digsListingHelpers';
// TODO: Move ClosablePopup to lib/*
import { ClosablePopup } from '../digs-search-v2/filters/ClosablePopup';
import { PopupInner } from '../digs-search-v2/filters/CommonStyles';
import { FeatureCategory } from 'models/property';
import TenantPreferences from './TenantPreferences';
import CommunitySection from './CommunitySection';
import RoomDetailsModal from './RoomDetailsModal';
import SafetyTips from './SafetyTips';

ReactModal.setAppElement('#root');

const { colors } = theme;

class DigsListing extends Component {
	constructor(props) {
		super(props);
		this.mapsSectionRef = React.createRef();
		this.triggerElementRef = React.createRef();

		this.state = {
			isLoading: true,
			isUnlisted: false,
			activeBookingInformation: null,
			showRentalDetail: false,
			bookingFormPage: 1,
			hasOpenListingBooking: false,
			isSharePopupOpen: false,
			isPreview: window.location.pathname.endsWith('preview'),
			selectedRoom: null,
		};
	}

	async componentWillMount() {
		const { id } = this.props.match.params;
		const response = this.state.isPreview
			? await api.searchV2.digsPreview(id)
			: await api.searchV2.digs(id);
		const listing = response.data;

		if (response.status !== 200) {
			this.setState({
				isUnlisted: true,
				isLoading: false,
			});
			return;
		}

		if (this.state.isPreview) {
			analyticsService.trackPage(PageName.ListingPreview);
		} else {
			analyticsService.trackPage(PageName.Listing, {
				digs_listing_uuid: listing.uuid,
				property_type: listing.sub_type,
				listing_type: listing.lease_type,
				price: listing.price,
				price_currency: listing.currency?.symbol,
				city: listing.location?.city,
				suburb: listing.location?.suburb,
				country: 'ZA',
			});
		}

		this.setState({
			isLoading: false,
			digs: getDigsStateData(listing),
		});

		api.events.send({
			event_type: EventType.DigsListing,
			meta: listing.booking_information,
		});

		if (!listing.external) {
			api.searchV2.getLandlordStats(listing.landlord.uuid).then(res => {
				if (!res.data) {
					return;
				}
				this.setState({
					landlordReplyRatePercentage: res.data.response_rate_percentage,
					landlordResponseTimeHours: res.data.response_time_hours,
				});
			});
		}
	}

	componentDidMount() {

		const { id } = this.props.match.params;
		api.searchV2.getSimilarListings(id).then(response => {
			this.setState({
				similarListings: response.data,
			});
		});
		api.searchV2.getCommunity(id).then(response => {
			this.setState({
				community: response.data,
			});
		});
		if (authContainer.state.token) {
			api.bookings
				.getBookings()
				.then(response => response.data)
				.then(bookings => {
					this.setState({
						hasOpenListingBooking:
							Boolean(bookings) &&
							bookings.some(
								booking =>
									booking.listing_uuid === id &&
									(booking.status === BookingStatus.Pending ||
										booking.status === BookingStatus.Accepted),
							),
					});
				});
		}
	}

	renderLandlordSection = () => {
		const { digs, landlordReplyRatePercentage, landlordResponseTimeHours } = this.state;

		const {
			biography,
			first_name,
			id,
			profile_picture,
			verifications,
			join_date,
			is_trusted_landlord,
		} = digs.landlord;

		const { listingId } = digs;

		return (
			<Subscribe to={[modalContainer]}>
				{modal => (
					<LandlordSection
						biography={biography}
						first_name={first_name}
						id={id}
						profile_picture={profile_picture}
						verifications={verifications}
						listingId={listingId}
						join_date={join_date}
						setModal={modal.set}
						is_trusted_landlord={is_trusted_landlord}
						replyRatePercentage={landlordReplyRatePercentage}
						responseTimeHours={landlordResponseTimeHours}
						listing={digs}
					/>
				)}
			</Subscribe>
		);
	};

	onBookNowClick = activeBookingInformation => {
		this.setState({
			activeBookingInformation: activeBookingInformation,
		});

		api.events.send({
			event_type: EventType.Enquire,
			meta: activeBookingInformation,
		});

		const listingId = this.props.match.params.id;
		this.navigateToEnquiry(listingId, activeBookingInformation);
	};

	handleFavouriteToggle = () => {
		if (!authContainer.state.token) {
			modalContainer.set(ModalType.Login);
			return;
		}
		const { digs } = this.state;
		const isFavourite = digs.favourite;

		this.setState({
			digs: {
				...digs,
				favourite: !isFavourite,
			},
		});
		if (!isFavourite) {
			api.favouritesV2.createFavourite(digs.uuid);
		} else {
			api.favouritesV2.deleteFavourite(digs.uuid);
		}
	};

	handleSharePopupOpen = () => {
		this.setState({
			isSharePopupOpen: true,
		});
	};

	handleSharePopupClose = () => {
		this.setState({
			isSharePopupOpen: false,
		});
	};

	handleProfileSetupClick = () => {
		modalContainer.set(ModalType.ProfileSetupInstant, {
			onComplete: () => {
				const listingId = this.props.match.params.id;
				this.navigateToEnquiry(listingId, this.state.activeBookingInformation);
			},
		});
	};

	navigateToEnquiry = (listingId, activeBookingInformation) => {
		const propertyId = activeBookingInformation.property_uuid;
		const roomId = activeBookingInformation && activeBookingInformation.room_uuid;
		if (roomId) {
			this.props.history.push(`/digs/enquire/${listingId}/${propertyId}/${roomId}`);
			return;
		}
		this.props.history.push(`/digs/enquire/${listingId}/${propertyId}`);
		return;
	};

	renderHelmetModifier = () => {
		const { digs } = this.state;
		const { title, listingId, photos, location, university_distances } = digs;

		const uniMeta = university_distances[0]
			? ` Student housing near ${university_distances[0].name}.`
			: '';

		const cityMeta = location.suburb ? `, ${location.city}` : location.city || '';

		const description = `Student accommodation in ${(location.suburb || '') + cityMeta}.${uniMeta}`;

		const city_slug = formatSlug(location.city || '');
		const suburb_slug = formatSlug(location.suburb || '');
		const title_slug = formatSlug(title);

		return (
			<Helmet>
				<title itemProp="name" lang="en">
					{title}
				</title>
				<meta name="description" content={description} />
				<meta
					property="og:url"
					content={`https://www.digsconnect.com/digs/view/${city_slug}/${suburb_slug}/${title_slug}/${listingId}`}
				/>
				<meta property="og:type" content={'website'} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="twitter:description" content={description} />
				{photos && <meta property="og:image" content={photos[0] ? photos[0].url : ''} />}
			</Helmet>
		);
	};

	renderRentalTermDetails = () => {
		const { digs, showRentalDetail } = this.state;

		const { booking_cancel_policy, security_deposit, admin_fee } = digs;

		return (
			<RentalTermDetails
				show={showRentalDetail}
				onDismiss={this.onRentalDetailsClose}
				booking_cancel_policy={booking_cancel_policy}
				security_deposit={security_deposit}
				admin_fee={admin_fee}
			/>
		);
	};

	onShowRentalDetails = () => {
		this.setState({ showRentalDetail: true });
	};

	onRentalDetailsClose = () => {
		this.setState({ showRentalDetail: false });
	};

	renderMapsSection = () => {
		const { digs } = this.state;
		const { location, university_distances } = digs;

		return (
			<>
				<div ref={this.mapsSectionRef}>
					<MapSection
						lat={location.coords.latitude}
						lng={location.coords.longitude}
						universityDistances={university_distances}
						title={listingService.getLocationText(location)}
					/>
				</div>
				<Divider />
			</>
		);
	};

	onUniversityDistanceClick = () => {
		window.scrollTo(0, this.mapsSectionRef.current.offsetTop - 130); //account for height of search bar
	};

	handleNavigateToFindADigs = () => {
		this.props.history.push(routes.digs_search);
	};

	handleCopyToClipboardClick = () => {
		shareService.copyToClipboard(window.location.href);
		notifySuccess('Link copied');
		this.setState({
			isSharePopupOpen: false,
		});
	};

	handleSelectRoom = room => {
		this.setState({
			selectedRoom: room,
		});
	};

	handleCloseRoomModal = () => {
		this.setState({
			selectedRoom: null,
		});
	};

	render() {
		const {
			digs,
			isLoading,
			isUnlisted,
			similarListings,
			landlordReplyRatePercentage,
			landlordResponseTimeHours,
			isPreview,
			selectedRoom,
			community,
		} = this.state;

		if (isLoading) {
			return <Loading />;
		}

		if (isUnlisted) {
			return (
				<Main>
					<Header searchable />
					<LayoutContainer>
						<ListingUnavailableContainer>
							<Title>This digs is no longer available</Title>
							<Button isPink onClick={this.handleNavigateToFindADigs}>
								Find a digs
							</Button>
						</ListingUnavailableContainer>
					</LayoutContainer>
					<Footer />
				</Main>
			);
		}

		const {
			features,
			photos,
			price,
			is_single_lease,
			rooms,
			display_price_from,
			is_available,
			title,
			location,
			university_distances,
			description,
			booking_cancel_policy,
			security_deposit,
			admin_fee,
			booking_information,
			feature_highlights,
			availability_date,
			sub_type,
			roomsList,
		} = digs;

		const iconStyle = {
			marginLeft: 16,
			height: 26,
		};

		return (
			<Main>
				{this.renderHelmetModifier()}
				{this.renderRentalTermDetails()}
				<Header searchable />
				{isPreview && <PreviewDisclosure>This is a preview of your listing</PreviewDisclosure>}
				<ImageOverview images={photos} videos={digs.videos} />
				{featureFlagContainer.isEnabled(FeatureFlag.ListingBackButton) && (
					<MobileOnly>
						{!isPreview && (
							<>
								<OverlayButton to={routes.digs_search}>Back to search</OverlayButton>
								<OverlayIcon onClick={this.handleSharePopupOpen}>
									<ShareIcon height={20} />
								</OverlayIcon>
							</>
						)}
						{this.state.isSharePopupOpen && (
							<ClosablePopup onClose={this.handleSharePopupClose}>
								<PopupInner>
									<Cross onClick={this.handleSharePopupClose} />
									<ShareTitle>Share this listing with family and friends!</ShareTitle>
									<Divider />
									<LinksContainer>
										<ShareLink largeMargin onClick={this.handleCopyToClipboardClick}>
											<ShareLinkText>Copy link</ShareLinkText>
											<LinkIcon style={iconStyle} />
										</ShareLink>
										<a
											href={shareService.getWhatsappShareLink(window.location.href)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<ShareLink>
												<ShareLinkText>Whatsapp</ShareLinkText>
												<WhatsappIcon style={iconStyle} />
											</ShareLink>
										</a>
										<a href={shareService.getEmailShareLink(window.location.href)}>
											<ShareLink>
												<ShareLinkText>Email</ShareLinkText>
												<EmailIcon style={iconStyle} />
											</ShareLink>
										</a>
										<a
											href={shareService.getFacebookShareLink(window.location.path)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<ShareLink>
												<ShareLinkText>Facebook</ShareLinkText>
												<FacebookIcon style={iconStyle} />
											</ShareLink>
										</a>
									</LinksContainer>
								</PopupInner>
							</ClosablePopup>
						)}
					</MobileOnly>
				)}
				<LayoutContainer>
					<div className="columns is-desktop pt-5">
						<div className={`column is-7-desktop is-7-widescreen is-8-fullhd`}>
							<ListingDescription
								is_single_lease={is_single_lease}
								display_price_from={display_price_from}
								is_available={is_available}
								title={title}
								location={location}
								university_distances={university_distances}
								feature_highlights={feature_highlights}
								description={description}
								rooms={rooms}
								roomsList={roomsList}
								availability_date={availability_date}
								onUniversityDistanceClick={this.onUniversityDistanceClick}
								sub_type={sub_type}
								nsfas_accredited={digs.nsfas_accredited}
								varsity_college_accredited={digs.varsity_college_accredited}
								total_occupants={digs.total_occupants}
								total_places_available={digs.total_places_available}
								total_places_available_value={digs.total_places_available_value}
								total_bathrooms={digs.total_bathrooms}
								bedroom_count={digs.bedroom_count}
								is_trusted_landlord={digs.landlord.is_trusted_landlord}
								replyRatePercentage={landlordReplyRatePercentage}
								responseTimeHours={landlordResponseTimeHours}
								listing={digs}
								onRoomClick={this.handleSelectRoom}
							>
								{this.renderMapsSection()}
							</ListingDescription>
							{!featureFlagContainer.isEnabled(FeatureFlag.ListingRoomCards) && <Divider />}
							<ListingFeatures
								features={features}
								categoryName={FeatureCategory.LeaseConditions}
								includedCategories={[FeatureCategory.LeaseConditions]}
								currency={digs.currency}
								customTitle={'Additional costs'}
								hasDivider
							/>
							<ListingFeatures
								features={features}
								categoryName={FeatureCategory.Furniture}
								includedCategories={[FeatureCategory.Furniture, FeatureCategory.Amenities]}
								customTitle={'Furniture & appliances included'}
								hasDivider
							/>
							<ListingFeatures
								features={features}
								categoryName={ListingFeatureCategory.OfferedFeatures}
								includedCategories={[
									FeatureCategory.Facilities,
									FeatureCategory.Security,
									FeatureCategory.Bills,
								]}
								customTitle={'This place offers'}
								hasDivider
							/>
							{Boolean(digs.preferred_genders) ||
								(digs.tenant_preferences && Boolean(digs.tenant_preferences.length) && (
									<>
										<TenantPreferences
											preferredGender={digs.preferred_genders}
											tenantTypes={digs.tenant_preferences}
										/>
										<Divider />
									</>
								))}
							{community && community.total_placements > 0 && (
								<>
									<CommunitySection communityData={community} />
									<Divider />
								</>
							)}
							{digs.videos && digs.videos[0] && digs.videos[0].url && (
								<>
									<SubTitle>Video tour</SubTitle>
									<InfoText>
										Check out this guided tour of the listing by clicking on the video below:
									</InfoText>
									<Video videoUrl={digs.videos[0].url} />
									<Divider />
								</>
							)}
							<ListingFeatures
								features={features}
								categoryName={FeatureCategory.Rules}
								includedCategories={[FeatureCategory.Rules]}
								customTitle={'Rules'}
							/>
							<SafetyTips />
						</div>
						<div
							className={`column is-5-desktop is-5-widescreen is-4-fullhd is-0-mobile`}
							style={{ paddingBottom: 0, paddingTop: 12 }}
						>
							<BookingPanel
								price={price}
								is_single_lease={is_single_lease}
								rooms={roomsList}
								display_price_from={display_price_from}
								onBookNowClick={this.onBookNowClick}
								booking_cancel_policy={booking_cancel_policy}
								security_deposit={security_deposit}
								admin_fee={admin_fee}
								bookingInformation={booking_information}
								nsfas_accredited={digs.nsfas_accredited}
								varsity_college_accredited={digs.varsity_college_accredited}
								aie_accredited={digs.aie_accredited}
								sub_type={sub_type}
								currency={digs.currency}
								listing={digs}
								isPreview={this.state.isPreview}
								replyRatePercentage={landlordReplyRatePercentage}
								responseTimeHours={landlordResponseTimeHours}
								hasOpenEnquiry={this.state.hasOpenListingBooking}
								onToggleFavourite={this.handleFavouriteToggle}
							/>
						</div>
					</div>
					{similarListings && !isPreview && Boolean(similarListings.length) ? (
						<>
							<Divider />
							<SimilarListingsAccordion
								titleText="More places like this"
								listings={similarListings}
							/>
						</>
					) : (
						<Spacer />
					)}
				</LayoutContainer>
				<Footer />
				{selectedRoom && (
					<RoomDetailsModal room={selectedRoom} onClose={this.handleCloseRoomModal} />
				)}
			</Main>
		);
	}
}

export default DigsListing;

const ListingUnavailableContainer = styled.div`
	text-align: center;
	margin-bottom: 100px;
	padding-top: 48px;
`;

const PreviewDisclosure = styled.div`
	background: ${colors.darkBlue};
	color: ${colors.white};
	text-align: center;
	width: 100%;
	padding: 28px 24px;
	font-size: 16px;
	font-weight: 600;
`;

const InfoText = styled.div`
	font-weight: 400;
	color: ${colors.grey60};
	font-size: 14px;
	margin-bottom: 24px;
`;

const Spacer = styled.div`
	margin-bottom: 24px;
`;

const MobileOnly = styled.div`
	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX + 1}px) {
		display: none;
	}
`;

const DesktopOnly = styled.div`
	@media (max-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		display: none;
	}
`;

const OverlayButton = styled(Link)`
	color: ${colors.darkBlue};
	position: absolute;
	top: 80px;
	left: 8px;
	background: ${colors.white};
	border-radius: 60px;
	z-index: 1;
	padding: 8px 16px;
	font-weight: 600;
	font-size: 14px;
`;

const OverlayIcon = styled(Link)`
	background: ${colors.white};
	border-radius: 50px;
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 80px;
	right: 8px;
`;

const ShareTitle = styled.div`
	margin: 16px 0;
	font-weight: 600;
	font-size: 14px;
	text-align: center;
`;

const ShareLink = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 20px;
	border-radius: 8px;
	background: ${colors.grey05};
	margin-bottom: ${({ largeMargin }) => (largeMargin ? 24 : 10)}px;
	cursor: pointer;
	color: ${colors.darkBlue};
`;

const ShareLinkText = styled.div`
	font-weight: 600;
	font-size: 14px;
`;

const LinksContainer = styled.div`
	max-height: 80vh;
	overflow-y: auto;
`;
