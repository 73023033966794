import React, { useState } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { coverImage } from 'config/mixins';
import { Listing } from 'models/listing';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { ReactComponent as TimerIcon } from 'assets/images/digs-listing/timer.svg';
import { ReactComponent as MessageSquareIcon } from 'assets/images/digs-listing/message_square.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/digs-listing/calendar.svg';
import { getLandlordDetailsTooltipText, InfoTooltipType } from './landlordDetailsTooltipText';
import { ReactComponent as InfoIconSvg } from 'assets/images/icons/info-circle-outline.svg';
import Tag from 'assets/images/digs-listing/tag.png';
import Cross from 'lib/Cross';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import ReportListing from 'components/ReportListing';
import authContainer from 'containers/auth';
import { Subscribe } from 'unstated';
const { colors, zIndices } = theme;

interface LandlordDetailsProps {
	listing: Listing;
	profilePictureUrl: string;
	isLarge?: boolean;
	replyRatePercentage?: number;
	responseTimeHours?: number;
	isTrustedLandlord?: boolean;
}

const LandlordDetails = ({
	listing,
	profilePictureUrl,
	isLarge,
	replyRatePercentage,
	responseTimeHours,
	isTrustedLandlord,
}: LandlordDetailsProps) => {
	const [infoTooltip, setInfoTooltip] = useState(InfoTooltipType.None);

	const handleLoginClick = (): void => {
		modalContainer.set(ModalType.Login, {
			onLoginSuccess: () => {
				modalContainer.set(ModalType.ReportListing);
			},
		});
	};

	return (
		<>
			<LandlordNameContainer largeText={isLarge}>
				{listing.external ? 'Property Contact' : 'About the landlord'}
			</LandlordNameContainer>
			<LandlordSection>
				<LandlordSectionLeftPanel>
					<LandlordSectionProfilePicture src={listing.landlord.profile_picture as any} />
					<LandlordNameText>
						{listing.external ? 'Digs International' : listing.landlord.first_name}
					</LandlordNameText>
				</LandlordSectionLeftPanel>
				<LandlordSectionRightPanel>
					<LandlordStatContainer>
						<LandlordStat>
							<LandlordIconContainer>
								<CalendarIcon fill={colors.darkBlue} width={32} />
							</LandlordIconContainer>
							<LandlordStatText>
								Joined DigsConnect in {listing.landlord.join_date.split('-')[0]}
							</LandlordStatText>
						</LandlordStat>
					</LandlordStatContainer>
					{!!responseTimeHours && (
						<LandlordStatContainer>
							<LandlordStat>
								<LandlordIconContainer>
									<TimerIcon fill={colors.darkBlue} width={32} />
								</LandlordIconContainer>
								<LandlordStatText>Replies in {responseTimeHours} hours</LandlordStatText>
							</LandlordStat>
							<InfoIcon onClick={() => setInfoTooltip(InfoTooltipType.ResponseTime)} />
							{infoTooltip === InfoTooltipType.ResponseTime && (
								<InfoTextPopup onClick={() => setInfoTooltip(InfoTooltipType.None)}>
									<Cross style={{ right: 8, top: 8, position: 'absolute' }} />
									{getLandlordDetailsTooltipText(InfoTooltipType.ResponseTime)}
								</InfoTextPopup>
							)}
						</LandlordStatContainer>
					)}
					{!!replyRatePercentage && (
						<LandlordStatContainer>
							<LandlordStat>
								<LandlordIconContainer>
									<MessageSquareIcon fill={colors.darkBlue} width={32} />
								</LandlordIconContainer>
								<LandlordStatText>{replyRatePercentage}% reply rate</LandlordStatText>
							</LandlordStat>
							<InfoIcon onClick={() => setInfoTooltip(InfoTooltipType.ReplyRate)} />
							{infoTooltip === InfoTooltipType.ReplyRate && (
								<InfoTextPopup onClick={() => setInfoTooltip(InfoTooltipType.None)}>
									<Cross style={{ right: 8, top: 8, position: 'absolute' }} />
									{getLandlordDetailsTooltipText(InfoTooltipType.ReplyRate)}
								</InfoTextPopup>
							)}
						</LandlordStatContainer>
					)}
					{!!isTrustedLandlord && (
						<LandlordStatContainer>
							<LandlordStat>
								<LandlordIconImageContainer>
									<img src={Tag} width={26} />
								</LandlordIconImageContainer>
								<LandlordStatText>Trusted landlord</LandlordStatText>
							</LandlordStat>
							<InfoIcon onClick={() => setInfoTooltip(InfoTooltipType.TrustedLandlord)} />
							{infoTooltip === InfoTooltipType.TrustedLandlord && (
								<InfoTextPopup onClick={() => setInfoTooltip(InfoTooltipType.None)}>
									<Cross style={{ right: 8, top: 8, position: 'absolute' }} />
									{getLandlordDetailsTooltipText(InfoTooltipType.TrustedLandlord)}
								</InfoTextPopup>
							)}
						</LandlordStatContainer>
					)}
				</LandlordSectionRightPanel>
			</LandlordSection>
			<Subscribe to={[authContainer]}>
				{auth => (
					<ReportListing showLoginPrompt={!auth.state.token} onLoginClick={handleLoginClick} />
				)}
			</Subscribe>
		</>
	);
};

export default LandlordDetails;

const NameContainer = styled.div`
	height: fit-content;
`;

const ProfileWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	margin-bottom: 16px;
`;

const DateText = styled.p`
	font-weight: 400;
	font-size: 14px;
	color: ${colors.grey60};
`;

const LandlordNameContainer = styled.div<{ largeText?: boolean }>`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
	${({ largeText }) => (largeText ? 'font-size: 20px' : '')}
`;

const NameText = styled.p`
	color: ${colors.darkBlue};
	font-size: 16px;
	font-weight: 600;
`;

const Picture = styled.img<{ isLarge?: boolean }>`
	${coverImage}
	width: 56px;
	height: 56px;
	border-radius: 50%;
	margin-right: 16px;
	background-color: ${colors.gray};
	${({ isLarge }) =>
		isLarge
			? `
    width: 72px;
	  height: 72px;
  `
			: ''}
`;

const LandlordSection = styled.div`
	display: flex;
`;

const LandlordSectionLeftPanel = styled.div`
	width: 25%;
	max-width: 100px;
	min-width: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
`;

const LandlordSectionRightPanel = styled.div`
	margin-top: 8px;
	flex: 1;
`;

const LandlordSectionProfilePicture = styled.img`
	${coverImage}
	min-width: 70px;
	min-height: 70px;
	border-radius: 50%;
	background-color: ${colors.gray};
	margin-top: 8px;
	margin-bottom: 8px;
`;

const LandlordNameText = styled.p`
	color: ${colors.darkBlue};
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
	margin-left: 8px;
`;

const LandlordStatContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
`;

const LandlordStat = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
`;

const LandlordStatText = styled.div`
	color: ${colors.darkBlue};
	font-weight: 600;
	font-size: 12px;
`;

const LandlordIconContainer = styled.div`
	margin-right: 4px;
`;

const LandlordIconImageContainer = styled(LandlordIconContainer)`
	display: flex;
	width: 32px;
	justify-content: center;
	align-items: center;
`;

const InfoIcon = styled(InfoIconSvg)<{ isWhite?: boolean }>`
	width: 16px;
	height: 16px;
	fill: ${({ isWhite }) => (isWhite ? colors.white : colors.grey60)};
	cursor: pointer;
	margin-right: 8px;
`;

const InfoTextPopup = styled.div`
	position: absolute;
	border: 1px solid ${colors.grey20};
	padding: 16px;
	padding-top: 24px;
	border-radius: 12px;
	background: ${colors.white};
	right: 0;
	left: 16px;
	top: 24px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: ${zIndices.mapOverlay + 1};
`;
